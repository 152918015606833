
/*** Importations ******************************************************************************/

// Polyfills

import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.append';

import 'classlist-polyfill';
import 'unfetch/polyfill';
//import focusWithin from 'focus-within-polyfill/src/focus-within.js';
import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import smoothscroll from 'smoothscroll-polyfill';

// Libraries

import InfiniteScroll from 'infinite-scroll';
import LazyLoad from 'vanilla-lazyload/dist/lazyload.esm.js';
import * as basicLightbox from 'basiclightbox/src/scripts/main.js';
// import { tns } from 'tiny-slider/src/tiny-slider.module.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';
import Glide from '@glidejs/glide/dist/glide.esm.js';
import Swiper, { Autoplay, Pagination } from 'swiper/core';
import simpleParallax from 'simple-parallax-js';

// Particles.js est incompatible avec le mode strict. Il est enqueué dans Bones.

// Scripts Nubee

import * as functions from 'partials/functions.js';
import MobileMenu from 'partials/mobile-menu.js';
import PageMenu from 'partials/page-menu.js';
import toggleSearchForm from 'partials/toggle-search-form.js';
import imgCheck from 'partials/img-check.js';
import AjaxPopup from 'partials/ajax-popup.js';
import NubeeLightbox from 'partials/nubee-lightbox.js';
import placeholderLabelUpdate from 'partials/placeholder-label.js';
import FormValidation from 'partials/form-validation.js';
import SwipeFinger from 'partials/swipe-finger.js';
import ShootingStar from 'partials/shooting-star.js';
import GoogleMap from 'partials/google-map.js';
import { Gradient } from 'gradientAnimation';
import * as cookiesBar from 'partials/cookies-bar.js';

(function() {

	/*** Slider des nouvelles ********************************************************************/
	(function() {
		Swiper.use( [ Pagination ] );

		var swiper = new Swiper(".mySwiper", {
			// direction: "vertical",
			slidesPerView: 1,
			spaceBetween: 20,
			touchEventsTarget: 'wrapper',
			preventInteractionOnTransition: true,
			preventClicks: false,
			preventClicksPropagation: false,
			grabCursor: true,
			lazy: {
				loadPrevNext: true,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: 'true',
			},
			on: {
				beforeLoopFix: function( ) {
					window.siteLazyLoad.update();
				}
			},
			breakpoints: {
				800: {
				  slidesPerView: 2,
				  spaceBetween: 40,
				},
			}
		});
    }) ();


	/*** Animation de dégradé du hero de l'accueil ***********************************************/
	(function() {
		const gradientCanvas = document.querySelectorAll( '.gradient-animation__canvas' );

		gradientCanvas.forEach( function( thisCanva ) {

			if( thisCanva !== undefined ) {
				new Gradient( thisCanva, ['#b4b2f5', '#f2f1ff' /*,'#d3bdd4', '#e1f77a', '#f97d53'*/], {
					amplitude: 1,
					seed: 2,
					scaleY: 0.5,
					scaleX: 1
				});
			}

		});

	}) ();


	/*** Application des polyfills *****************************************************************/

	(function() {
		objectFitImages();
		smoothscroll.polyfill();

		if( 'function' != typeof window.Event ) {
			window.Event = CustomEvent;
		}
	}) ();


	/*** Variables globales ************************************************************************/

	window.vWidth = document.documentElement.clientWidth;
	window.vHeight = document.documentElement.clientHeight;

	if( window.vWidth >= 800 ) {
		let pageMenuEl = document.querySelector('.page-menu');
		window.fixedElHeight = pageMenuEl ? pageMenuEl.clientHeight : 0;
	} else {
		window.fixedElHeight = 0;
	}

	window.fixedElWidth = 0;

	window.vWidthExceptFixed = document.documentElement.clientWidth - fixedElWidth;
	window.vHeightExceptFixed = window.vHeight - fixedElHeight;
	window.curScroll = {
		top: document.documentElement.scrollTop || document.body.scrollTop || 0,
		left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
		topExceptFixed: ( document.documentElement.scrollTop || document.body.scrollTop || 0 ) + window.fixedElHeight,
		leftExceptFixed: ( document.documentElement.scrollLeft || document.body.scrollLeft || 0 ) + window.fixedElWidth,
	};
	window.vCenter = {
		x: window.curScroll.left + ( window.vWidth / 2 ),
		y: window.curScroll.top + ( window.vHeight / 2 ),
		xExceptFixed: window.curScroll.left + ( window.vWidthExceptFixed / 2 ),
		yExceptFixed: window.curScroll.top + ( window.vHeightExceptFixed / 2 ),
	};
	window.ga = window.ga || window._gaq || null;
	window.lastWindowOpened = 0;

	var is_root = location.pathname == "/";

	// Chaîne traduisible : window.bonesGlobals.i18n.monPlugin.nomDeChaine


	/*** Mise à jour des mesures de l'écran au resize *********************************************/

	window.addEventListener( 'resize', function() {
		window.vWidth = document.documentElement.clientWidth;
		window.vHeight = document.documentElement.clientHeight;
		window.curScroll.top = document.documentElement.scrollTop || document.body.scrollTop;
		window.vCenter.y = window.curScroll.top + ( window.vHeight / 2 );
	} );


	/*** Les liens avec ancre qui mènent vers la même page deviennent seulement des ancres ****************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linksWithHash = document.querySelectorAll('a[href*="#"]');

		linksWithHash.forEach( function( thisLink ) {
			if( functions.urlsAreSamePage({ urls: [ thisLink.href, window.location.href ] }) ) {
				thisLink.href = thisLink.hash;
			}
		} );
	}) ();


	/*** Défiler doucement les liens ancres ****************************************************************/
	// Requiert la fonction functions.smoothScrollTo
	// Ajuster la variable globale fixedElHeight

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			document.querySelectorAll('a[href^="#"]:not([href="#"])').forEach( function( thisEl ) {
				thisEl.addEventListener( 'click', function( event ) {
					if( 1 == event.which && ! this.classList.contains('no-smooth-scroll') ) {
						let theHash;

						event.preventDefault();
						theHash = this.getAttribute('href');
						functions.smoothScrollTo( theHash );
					}
				} );
			});
		}
	}) ();


	/*** Ne rien faire au clic sur un lien vide (#) *********************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest('a');
				let theHref = linkClicked ? linkClicked.getAttribute('href') : null;

				if( linkClicked && ( ! theHref || '#' == theHref ) ) {
					event.preventDefault();
				}
			}
		} );
	}) ();


	/*** Défiler doucement au chargement d'une URL avec ancre ***********************************************/
	// Requiert la fonction functions.smoothScrollTo

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			let origHash = window.location.hash;

			if( origHash ) {
				// Blocage du jump du navigateur par défaut
				setTimeout( function() {
					// window.location.hash = '';
					// functions.removeHash();
				}, 1 );

				window.addEventListener( 'load', function() {
					functions.smoothScrollTo( document.querySelector( origHash ) );
				});
			}

		}
	}) ();


	/*** Images et backgrounds différents selon la taille de l'écran *****************************/
	// Requiert img-check.js

	(function() {
		if( 'function' == typeof imgCheck ) {
			window.addEventListener( 'resize', imgCheck );
			imgCheck();
		}
	}) ();


	/*** Lazy load des images et backgrounds ****************************************************************/
	// Requiert vanilla-lazyload

	(function() {
		window.siteLazyLoad = new LazyLoad({
			elements_selector: '.lazy',
			class_loading: 'lazy-loading',
			class_loaded: 'lazy-loaded',
			class_error: 'lazy-error',
		});
	}) ();


	/*** Sécurité pour les liens "nouvel onglet" ***********************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which || 2 == event.which ) {
				let linkClicked = event.target.closest('a');
				let linkTarget = linkClicked ? linkClicked.getAttribute('target') : null;
				let newWindow;
				let targetRegex = new RegExp( /^(_blank|_new)$/, 'i' )

				if( linkTarget && targetRegex.test( linkTarget ) ) {
					let linkUrl = linkClicked.getAttribute('href');

					event.preventDefault();
					newWindow = window.open( linkClicked.getAttribute('href'), 'targetBlankWindow_' + window.lastWindowOpened );

					++ window.lastWindowOpened;

					if( newWindow ) {
						newWindow.opener = null;
					}
				}
			}
		} );
	}) ();


	/*** Google Maps ***************************************************************************************/
	// Requiert google-map.js
	(function() {
		let mapParents = document.querySelectorAll('.map-parent');
		// https://snazzymaps.com/style/53/flat-map
		// Modifié
		let mapStyles = [
		    {
		        "featureType": "all",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "hue": "#6600ff"
		            },
		            {
		                "lightness": "76"
		            },
		            {
		                "saturation": "2"
		            }
		        ]
		    },
		    {
		        "featureType": "all",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "color": "#e8f4b7"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "geometry",
		        "stylers": [
		            {
		                "color": "#a7a7a7"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "labels",
		        "stylers": [
		            {
		                "saturation": "100"
		            },
		            {
		                "lightness": "34"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#f95d50"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative.land_parcel",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#f3eff9"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "hue": "#dbf372"
		            },
		            {
		                "saturation": "20"
		            },
		            {
		                "lightness": "40"
		            },
		            {
		                "gamma": "1"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape.man_made",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#dbf372"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#d0ff00"
					},
					{
						"visibility": "on"
					},
					{
						"lightness": "3"
					}
				]
			},
			{
				"featureType": "poi.medical",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#d0ff00"
					},
					{
						"visibility": "on"
					},
					{
						"lightness": "3"
					}
				]
			},
		    {
		        "featureType": "road",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#030537"
		            }
		        ]
		    },
		    {
		        "featureType": "road",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#c8c8f9"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "color": "#c8c8f9"
		            }
		        ]
		    },
		    {
		        "featureType": "road.local",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#ffffff"
		            },
		            {
		                "weight": 1.8
		            }
		        ]
		    },
		    {
		        "featureType": "road.local",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "color": "#c8c8f9"
		            }
		        ]
		    },
		    {
		        "featureType": "transit",
		        "elementType": "all",
		        "stylers": [
		            {
		                "color": "#5e5bf6"
		            },
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "water",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#030537"
		            }
		        ]
		    },
			{
				"featureType": "water",
				"elementType": "labels.text",
				"stylers": [
					{
						"color": "#c8c8f9"
					}
				]
			}
		];

		mapParents.forEach( function( thisParent ) {
			let map = new GoogleMap({
				mapParent: thisParent,
				styles: mapStyles,
			});
		} );
	}) ();


	/*** Antispam maison sur les commentaires *************************************************************/
	// À moins d'avoir activé le JS, la validation PHP refusera le commentaire.

	(function() {
		let commentForms = document.querySelectorAll('#commentform');

		commentForms.forEach( function( thisForm ) {
			thisForm.addEventListener( 'submit', function() {
				let legitInput = document.createElement( 'input' );
				legitInput.setAttribute( 'type', 'hidden' );
				legitInput.setAttribute( 'name', 'is_legit' );
				legitInput.value = 1;

				thisForm.appendChild( legitInput );
			} );
		} );
	}) ();


	/*** Validation de formulaire *************************************************************************/
	(function() {
		document.querySelectorAll( '.validate-form' ).forEach( function( thisForm ) {
			let formValidation = new FormValidation({
				form: thisForm,
			});
		} );
	}) ();


	/*** Placeholder labels - Un placeholder qui se tasse quand on écrit **********************************/
	// Requiert la classe sweet-form sur le formulaire
	// Requiert placeholder-label.js

	(function() {
		document.querySelectorAll('.has-placeholder-label input, .has-placeholder-label textarea').forEach( function( thisEl ) {
			placeholderLabelUpdate( thisEl );

			[ 'load', 'focus', 'blur', 'change', 'input' ].forEach( function( eventName ) {
				thisEl.addEventListener( eventName, placeholderLabelUpdate );
			} );
		} );
	}) ();


	/*** Retour en haut ***********************************************************************************/
	// Requiert functions.smoothScrollTo

	(function() {
		document.querySelector('.back-to-top').addEventListener( 'click', function() {
			if( 1 == event.which ) {
				functions.smoothScrollTo( document.body, 0 );
			}
		} );
	}) ();


	/*** Menu mobile maison *******************************************************************************/
	// Requiert mobile-menu.js

	(function() {
		window.mobileMenu = new MobileMenu({
			menuSel: '.mobile-menu',
			buttonSel: '.mobile-menu-button',
			debug: false,
		});
	}) ();


	/*** Menu de page à hash qui se met à jour avec le scroll *********************************************/
	// Requiert page-menu.js
	// Ne pas oublier d'appeler window.pageMenu.update() dans le scrollInterval

	(function() {
		window.pageMenu = new PageMenu({
			menuSel: '.page-menu',
		});
	}) ();


	/*** Regroupement d'évènements à intervalles réguliers ************************************************/
	// Plus performant qu'un évènement scroll

	(function() {
		window.scrollInterval = setInterval(function() {
			let lastScroll = window.curScroll;

			window.curScroll = {
				top: document.documentElement.scrollTop || document.body.scrollTop || 0,
				left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
			};
			window.vCenter = {
				x: window.curScroll.left + ( window.vWidth / 2 ),
				y: window.curScroll.top + ( window.vHeight / 2 ),
			};

			if( window.curScroll.top != lastScroll.top ) {
				/*** Glissement vers le haut et fade-in d'éléments ***/
				// Requiert la fonction functions.smoothFadeIn
				if( 'function' == typeof functions.smoothFadeIn ) {
					functions.smoothFadeIn();
				}

				// Mise à jour des classes active du menu de scroll
				if( window.pageMenu instanceof PageMenu ) {
					window.pageMenu.update();
				}
			}

			// Classes sur le body pour faire changer des CSS en fonction du scroll

			if( window.curScroll.top > 550 ) {
				document.body.classList.add('scrolled-a-lot');
			} else {
				document.body.classList.remove('scrolled-a-lot');
			}

			if( window.curScroll.top > 120 ) {
				document.body.classList.add('scrolled-a-bit');
			} else {
				document.body.classList.remove('scrolled-a-bit');
			}

		}, 100 );
	}) ();


	/*** Démarreur d'animation de la lune *********************************************/
	// (function() {
	// 	if (is_root) {
	// 		let moon = document.querySelector("div.moon");

	// 		window.addEventListener("scroll", function(){
	// 			if( window.scrollY >= 2000) {
	// 				moon.style.display = 'block';
	// 				moon.style.animation = "slide-in-elliptic-left-fwd 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both";
	// 			}
	// 		});
	// 	}
	// }) ();


	/*** Recherche qui s'ouvre/ferme en cliquant sur la loupe *********************************************/
	// Requiert toggle-search-form.js

	(function() {
		document.querySelectorAll('.search-form .search-button').forEach( function( thisEl ) {
			thisEl.addEventListener( 'click', function() {
				if( 1 == event.which ) {
					toggleSearchForm( event );
				}
			} );
		} );
	}) ();



	/*** Infinite Scroll - Charger les prochains articles automatiquement *******************************/
	// Requiert infinite-scroll.pkgd.min.js

	(function() {
		let infiniteContainers = document.querySelectorAll('.infinite-scroll');

		infiniteContainers.forEach(function( thisEl ) {

			if( thisEl.parentNode.querySelector('.pagination .next') ) {

				let infiniteInstance = new InfiniteScroll(
					'.infinite-scroll',
					{
						path: '.pagination .next',
						append: '.post',
						prefill: false,
						history: false,
						// outlayer: outlayer,
						hideNav: '.pagination',
						status: '.infinite-scroll-status',
						debug: false,
					}
				);

				infiniteInstance.on( 'append', function( event, response, path, items ) {

					imgCheck();
					window.siteLazyLoad.update();
				} );
			}
		});
	}) ();


	/*** Dropdown de médias sociaux ***********************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let buttonClicked = event.target.closest('.more-networks-button');
				let theContainer;
				let theDropdown;

				if( buttonClicked ) {
					let theContainer = buttonClicked.closest( '.more-networks' );
					let theDropdown = theContainer.querySelector( '.more-networks-dropdown' );

					if( ! theContainer.classList.contains('animating') ) {
						theContainer.classList.add( 'animating' );

						if( ! theContainer.classList.contains( 'open' ) ) {
							theContainer.classList.add( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeInOver( event ) {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeInOver );
							} );

						} else {
							theContainer.classList.remove( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeOutOver( event ) {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeOutOver );
							} );
						}
					}
				}
			}
		} );
	}) ();


	/*** Sliders Glide ****************************************************************************************/
	// Requiert Glide (@glidejs/glide)
	// Ce slider n'a pas de fonction tout-en-un disponible.

	// Partners logo slider
	(function() {
		let linkSliders = document.querySelectorAll('.partenaire .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			if( functions.scriptsOk( sliderEl ) ) {
				let sliderOptions = {
					type: 'carousel',
					perView: 5,
					gap: 50,
					bound: false,
					rewind: true,
					autoplay: 4000,
					focusAt: 'center',
					hoverpause: true,
					breakpoints: {
						500: {
							focusAt: 0,
							perView: 2,
						}
					}
				};

				let sliderInstance = new Glide( sliderEl, sliderOptions );

				sliderInstance.on( 'move', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.add( 'slider-animating' );
				} );

				sliderInstance.on( 'move.after', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.remove( 'slider-animating' );
				} );

				sliderInstance.mount();
			}
		} );
	}) ();


	/*** Sliders Owl Carousel ******************************************************************************/
	// Requiert la fonction functions.initSlider

	// (function() {
	// 	document.querySelectorAll('.slider').forEach( function( thisEl ) {
	// 		functions.initSlider( thisEl );
	// 	} );
	// }) ();

	/*** Sliders Tiny Slider *******************************************************************************/

	// (function() {
	// 	if (is_root) {
	// 		document.fonts.ready.then(function(){
	// 			let slider = tns({
	// 			    container: '.my-slider',
	// 			    items: 1,
	// 			    slideBy: 1,
	// 			    autoplay: true,
	// 				autoplayButtonOutput: false,
	// 			    axis: "vertical",
	// 				swipeAngle: false,
	// 				speed: 400,
	// 				controls: false
	// 			});
	// 		});
	// 	}
	// })();


	/*** Popups AJAX qui changent l'URL ********************************************************************/
	// Requiert ajax-popups.js
	// Requiert nubee-popup.js
	// Requiert basicLightbox
	// Requiert functions.loadingStart
	// Requiert functions.loadingEnd

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest( '.open-ajax-popup' );

				if( linkClicked ) {
					let ajaxPopup;
					event.preventDefault();

					ajaxPopup = new AjaxPopup({
						elementSelector: '.ajax-popup-content',
						ajaxUrl: linkClicked.href,
						// popupClass: '',
						// debug: true,
					});
				}
			}
		} );
	}) ();


	/*** Empêcher la taille d'un élément de se réajuster une fois le chargement terminé ********************/

	(function() {
		window.addEventListener( 'load', function( event ) {
			let lockedHeightEls = document.querySelectorAll('.lock-height-on-load');

			lockedHeightEls.forEach( function( thisEl ) {
				thisEl.style.height = thisEl.offsetHeight + 'px';
				thisEl.style.minHeight = 0;
			} );
		} );
	}) ();


	/*** Polyfill pseudo-sélecteur CSS :focus-within *******************************************************/
	// Requiert focus-within
	// Il ne s'active que si le navigateur ne supporte pas le pseudo-sélecteur.


	/*** Particules : Grille lignes et points animée ********************************************************/

	(function() {
		let scriptFolder = bonesGlobals.themeDir + '/library/js';

		if( document.body.classList.contains( 'page-bg-lines-animated' ) ) {
			particlesJS.load( 'particles-js', scriptFolder + '/data/particles.json', function() {
				// Callback
			});
		}
	}) ();


	/*** Select qui redirigent quand on choisit une option ***************************************************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linkSelects = document.querySelectorAll('.link-select');

		linkSelects.forEach( function( thisSelect ) {
			[ 'change', 'keypress' ].forEach( function( eventName ) {
				thisSelect.addEventListener( eventName, function() {
					let selectedOption = thisSelect.options[ thisSelect.selectedIndex ];
					let targetUrl = selectedOption.dataset.url;

					if( targetUrl && ! functions.urlsAreSamePage({ urls: targetUrl }) ) {
						window.location.href = targetUrl;
					}
				} );
			} );
		} );
	}) ();


	/*** Activation du doigt indicateur de scroll sur les blocs de statistiques appropriés *******************/
	// Requiert swipe-finger.js

	(function() {
		let fingerWrappers = document.querySelectorAll('.swipe-finger-wrapper');
		fingerWrappers.forEach( function( thisWrapper ) {
			let swipeFinger = new SwipeFinger({
				wrapper: thisWrapper,
				debug: true,
			});
		} );
	}) ();


	//load images after the page load
	document.addEventListener("DOMContentLoaded", function () {
		// Get our images
	 	var imagesAfterLoad = [].slice.call(document.querySelectorAll("div.after-page-load"));

		imagesAfterLoad.forEach(function(image){
			image.style.backgroundImage = 'url(' + image.dataset.background + ')';
		});
	});


	/*** Glissement vers le haut et fade-in d'éléments *****************************************************/
	// Requiert la fonction functions.smoothFadeIn
	if( 'function' == typeof functions.smoothFadeIn ) {
		functions.smoothFadeIn();
	}

	(function() {
		let faqEls = document.querySelectorAll( '.faq' );

		faqEls.forEach( function( thisFaq ) {
			let questions = thisFaq.querySelectorAll( '.question' );

			questions.forEach( function( thisQuestion ) {
				let questionTitle = thisQuestion.querySelector('.title');

				questionTitle.addEventListener( 'click', function() {
					let clickedQuestion = this.parentNode.parentNode;
					let questionText = this.parentNode.querySelector('.text');

					if( clickedQuestion.classList.contains('open') ) {
						clickedQuestion.classList.remove('open');
						questionText.style.height = 0 + 'px';

					} else {
						questions.forEach( function( questionToClose ) {
							let questionToCloseText = questionToClose.querySelector('.text');

							questionToClose.classList.remove( 'open' );
							questionToCloseText.style.height = 0 + 'px';
						} );

						let questionTextHeight = this.parentNode.querySelector('.text .text-inner').offsetHeight + 15;

						clickedQuestion.classList.add('open');
						questionText.style.height = questionTextHeight + 'px';
					}
				} );
			} );
		} );
	}) ();

	/*** Parallax sur le gros rond jaune *************/
	// Requiert simple-parallax-js

	// (function() {
	// 	var image = document.querySelectorAll('.wrap-site-background .planet');
	// 	new simpleParallax(image, {
	// 		orientation: 'left right',
	// 		delay: .8,
	// 		overflow: true
	// 	});
	// }) ();

	/*** Gestion des préférence de cookies ***************************************/
	(function() {
		window.acceptedCookies = cookiesBar.getCookie('acceptedCookies');

		if( 'function' == typeof cookiesBar.cookieConsent ) {
			cookiesBar.cookieConsent();
		}
	}) ();

}) ();