import * as functions from 'functions.js';
import * as nubeePopup from 'nubee-popup.js';

window.ajaxPopupOpen = false;

function NubeeLightbox( args ) {
	let that = this;
	args = args || [];

	args.changeUrl = args.changeUrl || true;
	args.debug = args.debug || false;
	args.slider = args.slider || 'basic';
	args.sliderClass = args.sliderClass || 'slider';
	args.sliderElClass = args.sliderElClass || 'slider-element';
	args.startPosition = args.startPosition || 0;

	this.items = args.items;
	this.args = args;
	this.error = null;
	this.loading = true;
	this.popup = null;

	this.args = args;

	this.init = function() {
		this.determineTypes();
	};

	this.determineTypes = function() {
		for( let index = 0; index < that.items.length; ++ index ) {
			
			if( ! that.items[index].type ) {
				if( that.items[index].html ) {
					that.items[index].type = 'html';
				} else if( /\.(png|jpg|jpeg|jp2|gif|apng|png|bmp|svg|webp|ico|xbm)$/i.test( that.items[index].url ) ) {
					that.items[index].type = 'image';
				} else if( /\.(webm|ogg|mp4|)$/i.test( that.items[index].url ) ) {
					that.items[index].type = 'video';
				} else {
					that.items[index].type = 'iframe';
				}
			}
		}
	};

	this.open = function() {
		let container = document.createElement( 'div' );
		let containerInner = document.createElement( 'div' );
		let popupArgs;

		container.classList.add( 'popup-content' );
		container.classList.add( 'lightbox-popup-content' );
		container.classList.add( 'elements-' + that.items.length );

		containerInner.classList.add( 'content-width' );
		containerInner.classList.add( 'popup-content-inner' );

		if( that.items.length > 1 ) {
			containerInner.classList.add( that.args.sliderClass );

			if( 'owl' == that.args.slider ) {
				containerInner.classList.add( 'drag' );
				containerInner.classList.add( 'nav' );
				containerInner.classList.add( 'fade' );
			}
		}

		that.items.forEach( function( thisItem ) {
			let itemEl = document.createElement( 'div' );
			let itemInnerEl = document.createElement( 'div' );
			let theItemEl;

			itemEl.classList.add( 'lightbox-element' );

			if( that.items.length > 1 ) {
				itemEl.classList.add( that.args.sliderElClass );
			}

			if( 'html' == thisItem.type ) {
				theItemEl = thisItem.html;
			} if( 'image' == thisItem.type ) {
				itemInnerEl.classList.add( 'wrap-fit-image' );
				theItemEl = document.createElement( 'img' );
				theItemEl.classList.add( 'the-item' );
				theItemEl.classList.add( 'fit-image' );
				theItemEl.setAttribute( 'src', thisItem.url );

				if( thisItem.alt ) {
					itemEl.setAttribute( 'alt', thisItem.alt );
				}
			} else if( 'video' == thisItem.type ) {
				console.error( 'Type "video" not supported yet.' );
			} else if( 'iframe' == thisItem.type ) {
				let theItemElInner = document.createElement( 'iframe' );
				theItemElInner.setAttribute( 'src', thisItem.url );

				itemInnerEl.classList.add( 'video-outer' );

				theItemEl = document.createElement( 'div' );
				theItemEl.classList.add( 'wrap-video' );

				if( thisItem.url.includes( 'youtu' ) ) {
					theItemElInner.setAttribute( 'allowfullscreen', 'allowfullscreen' );
				}

				theItemEl.append( theItemElInner );
			}

			itemInnerEl.append( theItemEl );
			itemEl.append( itemInnerEl );
			containerInner.append( itemEl );
		} );

		container.append( containerInner );

		popupArgs = {
			className: 'lightbox-popup',
		};

		if( that.items.length > 1 ) {
			popupArgs.className += ' has-' + that.args.sliderClass;
			popupArgs.onShow = function( instance ) {
				let popupEl = instance.element();
				let sliderEl = popupEl.querySelector( '.' + that.args.sliderClass );

				if( 'owl' == that.args.slider ) {
					functions.initSlider( sliderEl, {
						startPosition: that.args.startPosition,
					} );
				} else {
					console.error( "Slider type '" + that.args.slider + "' is not supported yet." );
				}
			};
		}

		that.popup = nubeePopup.create( container, popupArgs );
		that.popup.show();
	};


	// Initialisation

	this.init();
	this.open();
}

export default NubeeLightbox;