function placeholderLabelUpdate( input ) {
	if( input instanceof Event ) {
		input = input.target;
	}

	let parent = input.closest('.has-placeholder-label');
	let label = parent.querySelector('.placeholder-label');
	// console.log('value : "' + $(this).val() + '"');

	if( label ) {
		if( ! input.value ) {
			label.classList.remove('out-of-way');
		} else {
			label.classList.add('out-of-way');
		}
	}
}

export default placeholderLabelUpdate;