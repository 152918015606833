function toggleSearchForm( sourceEvent ) {
	let button = sourceEvent.target;
	let theWrapper = button.closest('.search-form');
	let theForm = button.closest('form');
	let theField = theForm.querySelector('.search-field');

	if( ! theWrapper.classList.contains('animating') ) {
		if( ! theWrapper.classList.contains('open') ) {
			sourceEvent.preventDefault();
			theWrapper.classList.add('animating');
			theWrapper.classList.add('open');

			theField.addEventListener( 'transitionend', function formOpened( event ) {
				event.target.focus();
				event.target.removeEventListener( 'transitionend', formOpened );
			} );
		} else if( ! theField.val() ) {
			sourceEvent.preventDefault();
			theWrapper.classList.add('animating');
			theWrapper.classList.remove('open');
		}

		theField.addEventListener( 'transitionend', function animationOver( event ) {
			theWrapper.classList.remove('animating');
			event.target.removeEventListener( 'transitionend', animationOver );
		} );
	} else {
		sourceEvent.preventDefault();
	}
}

export default toggleSearchForm;