import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import * as basicLightbox from 'basiclightbox/src/scripts/main.js';
import imgCheck from 'img-check.js';
import * as functions from 'functions.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';

export function create( content, options ) {
	options = options || {};
	let origOnShow = options.onShow || null;
	let origOnClose = options.onClose || null;
	let closeButton;
	let wrapButtons;
	let wrapButtonsInner;
	let popupInstance;
	let contentOuter;

	options.className = options.className || '';
	options.className += ' nubee-popup';
	options.className = options.className.trim();

	options.onShow = function( instance ) {
		let popupEl = instance.element();
		let closeButton = popupEl.querySelector( '.close-popup' );
		let popupImages = popupEl.querySelectorAll( 'img' );

		popupEl.classList.add( 'animating' );
		popupEl.classList.add( 'opening' );
		popupEl.classList.add( 'open' );
		popupEl.classList.add( 'admin-bar-offset' );

		popupEl.addEventListener( 'transitionend', function showOver( event ) {
			document.body.classList.add( 'popup-open' );
			disableBodyScroll( popupEl );
			popupEl.classList.remove( 'animating' );
			popupEl.classList.remove( 'opening' );

			imgCheck();
			window.siteLazyLoad.update();
			objectFitImages( popupImages );

			event.target.removeEventListener( 'transitionend', showOver );
		} );

		// Évènements de fermeture

		closeButton.addEventListener( 'click', function clickedButton( event ) {
			if( 1 == event.which ) {
				popupInstance.close();
				event.target.removeEventListener( 'click', clickedButton );
			}
		} );

		closeButton.addEventListener( 'keypress', function pressedEnterOnButton( event ) {
			let key = event.key || event.keyCode;
			if( 'Enter' == key || 13 == key ) {
				popupInstance.close();
				event.target.removeEventListener( 'keypress', pressedEnterOnButton );
			}
		} );

		document.addEventListener( 'keyup', function docKeypress( event ) {
			let key = event.key || event.keyCode;

			if( 'Escape' == key || 'Esc' == key || 27 == key ) {
				popupInstance.close();
				event.target.removeEventListener( 'click', docKeypress );
			}
		} );

		if( origOnShow ) {
			origOnShow( instance );
		}
	}

	options.onClose = function( instance ) {
		let popupEl = instance.element();

		popupEl.classList.add( 'animating' );
		popupEl.classList.add( 'closing' );
		popupEl.classList.remove( 'open' );
		document.body.classList.remove( 'popup-open' );
		enableBodyScroll( popupEl );

		popupEl.addEventListener( 'transitionend', function closeOver( event ) {
			popupEl.classList.remove( 'animating' );
			popupEl.classList.remove( 'closing' );

			event.target.removeEventListener( 'transitionend', closeOver );
		} );

		if( origOnClose ) {
			origOnClose( instance );
		}
	}

	if( ! content instanceof HTMLElement ) {
		let parser = new DOMParser();
		let contentDoc = parser.parseFromString( content );
		let content = contentDoc.documentElement;
	}

	wrapButtons = document.createElement( 'div' );
	wrapButtons.classList.add( 'popup-buttons' );
	wrapButtonsInner = document.createElement( 'div' );
	wrapButtonsInner.classList.add( 'popup-buttons-inner' );
	wrapButtonsInner.classList.add( 'content-width' );

	closeButton = document.querySelector('.footer-hidden-elements .close-popup').cloneNode( true );

	wrapButtonsInner.append( closeButton );
	wrapButtons.append( wrapButtonsInner );

	// basicLightbox "jette" l'élément parent que je lui donne. Donnons-lui un div inutile à jeter.
	contentOuter = document.createElement('div');
	contentOuter.append( content );
	content.prepend( wrapButtons );
	popupInstance = basicLightbox.create( contentOuter, options );

	return popupInstance;
}