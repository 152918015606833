function SwipeFinger( args ) {
	let that = this;

	args = args || {};
	args.debug = args.debug || false;
	args.wrapperInnerSel = args.wrapperInnerSel || '.swipe-finger-wrapper-inner';
	this.args = args;

	this.init = function() {
		that.wrapper = that.args.wrapper;
		that.update();
	};

	this.update = function() {
		if( that.wrapper.scrollWidth > that.wrapper.clientWidth && 0 == that.wrapper.scrollLeft ) {
			that.wrapper.classList.add( 'finger-enabled' );
			that.wrapper.addEventListener( 'scroll', that.update );
		} else {
			that.wrapper.classList.remove( 'finger-enabled' );
			that.wrapper.removeEventListener( 'scroll', that.update );
		}
	}

	this.init();
}

export default SwipeFinger;