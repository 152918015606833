/**
 * MiniGL Gradient Animation
 * Credit to Stripe.com for original code
 * Inspired by https://kevinhufnagl.com
 *
 * # Usage
 * const gradient = new Gradient(el<String|Element>, colors<String[]>, options?<Object>)
 *
 * # Options
 * amplitude: <Number> (1)
 * height: <Number> (600)
 * scaleX: <Number> (1)
 * scaleY: <Number> (1)
 * seed: <Number> (5)
 * static: <Boolean> (false)
 *
 * # Instance Props
 * Amplitude: Gradient.amp = 0
 * Colors: Gradient.sectionColors = []
 *
 * # Instance Methods
 * Gradient.pause()
 * Gradient.play()
 * Gradient.toggleColor(index)
 * Gradient.updateFrequency(freq)
 */

import MiniGl from './MiniGl'
import shaderFiles from './shaderFiles'

class Gradient {
  constructor(el, colors, options = {}) {
    const { amplitude = 1, height = 600, scaleX = 1, scaleY = 1, seed = 5, static: isStatic = false } = options

    this.el = typeof el === 'string' ? document.querySelector(el) : el
    this.height = height
    this.isStatic = isStatic
    this.seed = Math.max(seed, 2)
    this.freqDelta = 1e-5
    this.freqX = Math.abs(10e-5 * (scaleX || 1))
    this.freqY = Math.abs(22e-5 * (scaleY || 1))
    this.amp = Math.max(parseInt(240 * amplitude), 60)
    this.sectionColors = this.getGradientColors(colors)
    this.angle = 0
    this.isLoadedClass = false
    this.shaderFiles = shaderFiles
    this.computedCanvasStyle = null
    this.activeColors = [1, 1, 1, 1]
    this.uniforms = {}
    this.t = 1253106
    this.last = 0
    this.mesh = null
    this.width = null
    this.minigl = null
    this.minWidth = 1111
    this.material = null
    this.geometry = null
    this.xSegCount = null
    this.ySegCount = null

    this.conf = {
      presetName: '',
      wireframe: false,
      density: [0.06, 0.16],
      zoom: 1,
      rotation: 0,
      playing: false
    }

    this.animate = e => {
      if (!this.shouldSkipFrame(e)) {
        this.t += Math.min(e - this.last, 1e3 / 15)
        this.last = e
        this.mesh.material.uniforms.u_time.value = this.t
        this.minigl.render()
      }

      if (this.last > 0 && this.isStatic) {
        this.minigl.render()
        this.disconnect()
      }

      if (this.conf.playing) requestAnimationFrame(this.animate)
    }

    this.resize = () => {
      this.width = window.innerWidth
      this.minigl.setSize(this.width, this.height)
      this.minigl.setOrthographicCamera()
      this.xSegCount = Math.ceil(this.width * this.conf.density[0])
      this.ySegCount = Math.ceil(this.height * this.conf.density[1])
      this.mesh.geometry.setTopology(this.xSegCount, this.ySegCount)
      this.mesh.geometry.setSize(this.width, this.height)
      this.mesh.material.uniforms.u_shadow_power.value = this.width < 600 ? 5 : 6
    }

    this.init()
  }

  pause() {
    this.conf.playing = false
  }

  play() {
    requestAnimationFrame(this.animate)
    this.conf.playing = true
  }

  normalizeColor(hexCode) {
    return [((hexCode >> 16) & 255) / 255, ((hexCode >> 8) & 255) / 255, (255 & hexCode) / 255]
  }

  init() {
    if (!this.el) throw new Error('[Gradient] canvas not found')
    this.conf.playing = true
    this.minigl = new MiniGl(this.el, null, null, true)
    this.computedCanvasStyle = getComputedStyle(this.el)
    window.addEventListener('resize', this.resize)

    requestAnimationFrame(() => {
      this.initMesh()
      this.resize()
      this.addIsLoadedClass()
      requestAnimationFrame(this.animate)
    })
  }

  disconnect() {
    this.conf.playing = false
    window.removeEventListener('resize', this.resize)
  }

  updateFrequency(e) {
    this.freqX += e
    this.freqY += e
  }

  toggleColor(index) {
    this.activeColors[index] = Number(!this.activeColors[index])
  }

  getGradientColors(colors) {
    return colors
      .map(hex => {
        if (hex.length !== 4) return hex && `0x${hex.substr(1)}`

        const hexTemp = hex
          .substr(1)
          .split('')
          .map(hexTemp => hexTemp + hexTemp)
          .join('')

        const subHex = `#${hexTemp}`
        return `0x${subHex.substr(1)}`
      })
      .filter(Boolean)
      .map(this.normalizeColor)
  }

  shouldSkipFrame(e) {
    return !!window.document.hidden || !this.conf.playing || !(parseInt(e, 10) % 2)
  }

  addIsLoadedClass() {
    if (this.isLoadedClass) return
    this.isLoadedClass = true
    this.el.classList.add('isLoaded')

    setTimeout(() => {
      this.el.parentElement.classList.add('isLoaded')
    }, 3000)
  }

  initMesh() {
    this.material = this.initMaterial()
    this.geometry = new this.minigl.PlaneGeometry()
    this.mesh = new this.minigl.Mesh(this.geometry, this.material)
  }

  initMaterial() {
    this.uniforms = {
      u_time: new this.minigl.Uniform({ value: 0 }),
      u_shadow_power: new this.minigl.Uniform({ value: 5 }),
      u_darken_top: new this.minigl.Uniform({ value: '' === this.el.dataset.jsDarkenTop ? 1 : 0 }),
      u_active_colors: new this.minigl.Uniform({ value: this.activeColors, type: 'vec4' }),
      u_baseColor: new this.minigl.Uniform({ value: this.sectionColors[0], type: 'vec3', excludeFrom: 'fragment' }),
      u_waveLayers: new this.minigl.Uniform({ value: [], excludeFrom: 'fragment', type: 'array' }),
      u_global: new this.minigl.Uniform({
        type: 'struct',
        value: {
          noiseFreq: new this.minigl.Uniform({ value: [this.freqX, this.freqY], type: 'vec2' }),
          noiseSpeed: new this.minigl.Uniform({ value: 5e-6 })
        }
      }),
      u_vertDeform: new this.minigl.Uniform({
        type: 'struct',
        excludeFrom: 'fragment',
        value: {
          incline: new this.minigl.Uniform({ value: Math.sin(this.angle) / Math.cos(this.angle) }),
          offsetTop: new this.minigl.Uniform({ value: -0.5 }),
          offsetBottom: new this.minigl.Uniform({ value: -0.5 }),
          noiseFreq: new this.minigl.Uniform({ value: [3, 4], type: 'vec2' }),
          noiseAmp: new this.minigl.Uniform({ value: this.amp }),
          noiseSpeed: new this.minigl.Uniform({ value: 10 }),
          noiseFlow: new this.minigl.Uniform({ value: 3 }),
          noiseSeed: new this.minigl.Uniform({ value: this.seed })
        }
      })
    }

    this.uniforms.u_waveLayers.value.push(
      ...this.sectionColors.slice(1).map((clr, i) => {
        const e = i + 1
        return new this.minigl.Uniform({
          type: 'struct',
          value: {
            color: new this.minigl.Uniform({ value: clr, type: 'vec3' }),
            noiseFreq: new this.minigl.Uniform({
              value: [2 + e / this.sectionColors.length, 3 + e / this.sectionColors.length],
              type: 'vec2'
            }),
            noiseSpeed: new this.minigl.Uniform({ value: 11 + 0.3 * e }),
            noiseFlow: new this.minigl.Uniform({ value: 6.5 + 0.3 * e }),
            noiseSeed: new this.minigl.Uniform({ value: this.seed + 10 * e }),
            noiseFloor: new this.minigl.Uniform({ value: 0.1 }),
            noiseCeil: new this.minigl.Uniform({ value: 0.63 + 0.07 * e })
          }
        })
      })
    )

    const vertexShader = [this.shaderFiles.noise, this.shaderFiles.blend, this.shaderFiles.vertex].join('\n\n')
    return new this.minigl.Material(vertexShader, this.shaderFiles.fragment, this.uniforms)
  }
}

export default Gradient
