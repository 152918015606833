import * as functions from 'functions.js';
import * as nubeePopup from 'nubee-popup.js';

window.ajaxPopupOpen = false;

function AjaxPopup( args ) {
	let that = this;
	args = args || [];
	args.changeUrl = args.changeUrl || true;
	args.debug = args.debug || false;
	args.elementSelector = args.elementSelector || null;

	this.args = args;
	this.i18n = window.bonesGlobals.i18n.ajaxPopup;
	this.error = null;
	this.loading = true;

	this.throwError = function( errorType, titleArg, textArg ) {
		titleArg = titleArg || null;
		textArg = textArg || null;
		errorType = 'string' == typeof errorType ? errorType : errorType.message;

		if( that.args.debug ) console.log( 'throwError( ' + errorType + ', ' + titleArg + ', ' + textArg + ' )' );

		let titleEl = document.createElement( 'h1' );
		let textEl = document.createElement( 'div' );
		let parentEl = document.createElement( 'div' );
		let subParentEl = document.createElement( 'div' );
		let theTitle = this.i18n[errorType].title;
		let theText = this.i18n[errorType].text;
		let errorPopup;

		if( titleArg ) {
			theTitle = theTitle.replace( '%s', titleArg );
		}

		if( textArg ) {
			theText = theText.replace( '%s', textArg );
		}

		parentEl.classList.add( 'popup-content' );
		parentEl.classList.add( 'popup-error' );
		parentEl.classList.add( 'popup-error-' + errorType );
		subParentEl.classList.add( 'popup-content-inner' );
		subParentEl.classList.add( 'content-width' );
		titleEl.classList.add( 'error-title' );
		titleEl.classList.add( 'modal-title' );
		titleEl.innerHTML = theTitle;
		textEl.classList.add( 'error-text' );
		textEl.classList.add( 'modal-text' );
		textEl.classList.add( 'wysiwyg' );
		textEl.innerHTML = theText;

		subParentEl.append( titleEl, textEl );
		parentEl.append( subParentEl );

		errorPopup = nubeePopup.create( parentEl, {
			className: 'modal error',
			onShow: function( instance ) {
				let popupEl = instance.element();
				
				setTimeout( function() {
					functions.loadingEnd();
					that.loading = false;
				}, 100 );
			}
		} );

		errorPopup.show();
	}

	this.getContent = function() {
		let popupContent;
		let fetchOptions = {
			headers: {
				'X-Requested-With': 'XmlHttpRequest'
			}
		};

		functions.loadingStart();
		that.loading = true;

		if( that.args.ajaxUrl ) {
			let urlToContact = new URL( that.args.ajaxUrl );
			urlToContact.searchParams.set( 'ajax', 'true' );

			return fetch( urlToContact, fetchOptions )
				.then( function( response ) {
					if( response.ok ) {
						let responseText = response.text()
						if( that.args.debug ) console.log('got response :');
						if( that.args.debug ) console.log(responseText);
						return responseText;
					} else {
						that.error = 'badResponse';
						that.errorCode = response.status;
						throw new Error( that.error );
						return Promise.reject.bind(Promise);
					}
				} )
				.then( function( response ) {
					// On a ici un texte brut de la page entière. Ramassons juste ce qu'on veut.
					let parser = new DOMParser();
					let responseDoc = parser.parseFromString( response, 'text/html' );
					let targetEl = responseDoc.querySelector( that.args.elementSelector );

					if( targetEl ) {
						if( that.args.debug ) console.log('got target el :');
						if( that.args.debug ) console.log( targetEl );
						return targetEl;
					} else {
						that.error = 'selectorNotFound';
						throw new Error( that.error );
					}
				} )
				.catch( function( error ) {
					that.throwError( error, that.errorCode );
					return false;
				} );
			;
		} else {
			that.throwError( 'urlMissing' );
			return false;
		}
	}

	this.create = function( popupContentPromise ) {
		if( 'promise' != typeof popupContentPromise ) {
			popupContentPromise = Promise.resolve( popupContentPromise );
		}

		return popupContentPromise.then( function( popupContent ) {
			if( that.args.debug ) console.log( 'ready to create popup with content :' );
			if( that.args.debug ) console.log(popupContent);

			if( popupContent ) {

				let popupOptions = {};

				let popupClass = [
					'ajax-popup',
					that.args.popupClass,
				];

				if( 'string' == typeof popupContent ) {
					let parser = new DOMParser();
					popupContent = parser.parseFromString( popupContent );
					if( that.args.debug ) console.log('parser result:');
					if( that.args.debug ) console.log(popupContent);
					popupContent = popupContent.documentElement;
				}

				if( popupContent.dataset && popupContent.dataset.popupClass ) {
					popupClass.push( popupContent.dataset.popupClass );
				}

				popupOptions.className = popupClass.join( ' ' );

				popupOptions.onShow = function( instance ) {
					let popupEl = instance.element();
					let targetEl = popupEl.querySelector( that.args.elementSelector );

					setTimeout( function() {
						functions.loadingEnd();
						that.loading = false;
					}, 100 );
					
					if( that.args.changeUrl ) {
						functions.changeUrl({
							context: 'open',
							title: targetEl.dataset.elementTitle,
							id: targetEl.dataset.elementId,
							url: that.args.ajaxUrl,
						});
					}
				};

				if( that.args.changeUrl ) {
					popupOptions.onClose = function( instance ) {					
						functions.changeUrl({
							context: 'close',
						});
					};
				}

				let popupInstance = nubeePopup.create( popupContent, popupOptions );

				return popupInstance;
			} else {
				return false;
			}
		} );
	}

	this.open = function( popupContent ) {
		popupContent = popupContent || that.getContent();

		if( that.args.debug ) console.log('just got content :');
		if( that.args.debug ) console.log(popupContent);

		if( popupContent && ! that.popup ) {
			let popupPromise = that.create( popupContent );

			popupPromise.then( function( popupInstance ) {
				if( that.args.debug ) console.log( 'created popup instance :' );
				if( that.args.debug ) console.log( popupInstance );

				if( popupInstance ) {
					that.popup = popupInstance;
					that.popup.show();
				}
			} );
		}
	}


	// Initialisation

	this.open();
}

export default AjaxPopup;