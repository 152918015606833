// Requiert body-scroll-lock
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';

function MobileMenu( args ) {
	let that = this;

	args = args || {};

	// Taille de viewport appropriée pour que le code de sous-menus s'applique
	args.viewportMin = args.viewportMin || {};
	args.viewportMin.width = args.viewportMin.width || 0;
	args.viewportMin.height = args.viewportMin.height || 0;
	args.viewportMax = args.viewportMax || {};
	args.viewportMax.width = args.viewportMax.width || Infinity;
	args.viewportMax.height = args.viewportMax.height || Infinity;

	args.buttonSel = args.buttonSel || '.mobile-menu-button';
	args.menuSel = args.menuSel || '.mobile-menu';
	args.scrollableElSel = args.scrollableElSel || '.mobile-menu-scrollable-el';
	args.dropdownParentSel = args.dropdownParentSel || '.dropdown';
	args.dropdownSel = args.dropdownSel || '.dropdown-menu';
	// args.menuListSel = args.menuListSel || '.menu-list';
	args.linkSel = args.linkSel || '.menu-link';
	args.openClass = args.openClass || 'open';
	args.openingClass = args.openingClass || 'opening';
	args.closingClass = args.closingClass || 'closing';
	args.animatingClass = args.animatingClass || 'animating';
	args.popupOpenClass = args.popupOpenClass || 'popup-open';
	// Doit correspondre à la classe de menu actif de WP
	args.activeClass = args.activeClass || 'active';
	// Si true, le <a> fonctionne, et pour dérouler un sous-menu, il faut cliquer dans le <li> mais à l'extérieur du <a>. Si false, le <a> ne fait que dérouler le sous-menu.
	args.dropdownParentLinkActive = args.dropdownParentLinkActive || false;
	// Si true, ouvrir un sous-menu ferme tous les autres.
	args.singleSubmenu = args.singleSubmenu || true;
	args.debug = args.debug || false;

	this.args = args;



	this.init = function() {
		let activeMenus;

		if( that.args.debug ) console.log('Mobile menu : Initializing.');
		that.menu = document.querySelector( that.args.menuSel );

		if( that.menu ) {
			that.scrollableEl = document.querySelector( that.args.scrollableElSel );
			that.button = document.querySelector( that.args.buttonSel );
			that.dropdownParents = that.menu.querySelectorAll( that.args.dropdownParentSel );
			that.dropdownParentLinks = that.menu.querySelectorAll( that.args.dropdownParentSel + ' > ' + that.args.linkSel );

			that.button.classList.add( 'no-smooth-scroll' );

			/*
			// Définir la hauteur de tous les dropdown pour que les effets se fassent bien
			that.dropdownParents.forEach( function( thisEl ) {
				that.dropdown = thisEl.querySelector( args.dropdownSel );
				that.dropdownCount = that.dropdown.querySelectorAll('li').length;
				thisEl.setAttribute('data-dropdown-default-height', thisEl.offsetHeight );
				thisEl.setAttribute('data-dropdown-height', ( that.dropdown.offsetHeight + ( that.dropdownCount * 16 ) + thisEl.offsetHeight ) );
			});
			*/

			if( that.args.dropdownParentLinkActive ) {
				that.openSubmenuTarget = that.dropdownParents;
			} else {
				that.openSubmenuTarget = that.dropdownParentLinks;
			}

			that.hashLinks = that.menu.querySelectorAll( args.linkSel + '[href^="#"]' );

			// Pré-ouverture du menu actif
			activeMenus = that.menu.querySelectorAll( that.args.dropdownParentSel + '.' + that.args.activeClass );

			if( activeMenus ) {
				activeMenus.forEach( function( thisEl ) {
					that.openSubmenu( thisEl );
				} );
			}
		}
	}

	this.toggle = function( action ) {
		let finalAction = action = action || 'toggle';

		if( that.args.debug ) console.log('Mobile menu : Maybe toggling...');

		if( ! that.menu.classList.contains( that.args.animatingClass ) ) {
			if( 'toggle' == action ) {
				if( that.menu.classList.contains( that.args.openClass ) ) {
					finalAction = 'close';
				} else {
					finalAction = 'open';
				}
			}

			that.menu.classList.add( that.args.animatingClass );
			that.button.classList.add( that.args.animatingClass );

			if( 'open' == finalAction ) {
				if( ! that.menu.classList.contains( that.args.openClass ) ) {
					if( that.args.debug ) console.log('Mobile menu : opening');

					that.menu.classList.add( that.args.openingClass );
					that.button.classList.add( that.args.openingClass );
					that.menu.classList.add( that.args.openClass );
					that.button.classList.add( that.args.openClass );

					that.menu.addEventListener( 'transitionend', function openDone( event ) {
						if( that.args.debug ) console.log('Mobile menu : opening done!');

						disableBodyScroll( that.scrollableEl, { reserveScrollBarGap: true } );
						that.menu.classList.remove( that.args.openingClass );
						that.button.classList.remove( that.args.openingClass );
						document.documentElement.classList.add( that.args.popupOpenClass );

						that.menu.removeEventListener( 'transitionend', openDone );
					} );
				}
			} else {
				if( that.args.debug ) console.log('Mobile menu : closing');

				that.menu.classList.add( that.args.closingClass );
				that.button.classList.add( that.args.closingClass );
				that.menu.classList.remove( that.args.openClass );
				that.button.classList.remove( that.args.openClass );

				enableBodyScroll( that.scrollableEl, { reserveScrollBarGap: true } );
				document.documentElement.classList.remove( that.args.popupOpenClass );

				that.menu.addEventListener( 'transitionend', function closeDone( event ) {
					if( that.args.debug ) console.log('Mobile menu : closing done!');

					that.menu.classList.remove( that.args.closingClass );
					that.button.classList.remove( that.args.closingClass );

					that.menu.removeEventListener( 'transitionend', closeDone );
				} );
			}

			that.menu.addEventListener( 'transitionend', function animationDone( event ) {
				that.menu.classList.remove( that.args.animatingClass );
				that.button.classList.remove( that.args.animatingClass );

				that.menu.removeEventListener( 'transitionend', animationDone );
			} );
		}
	}

	this.open = function() {
		this.toggle( 'open' );
	}

	this.close = function() {
		this.toggle( 'close' );
	}

	this.openSubmenu = function( submenu ) {
		that.toggleSubmenu( submenu, 'open' );
	}

	this.closeSubmenu = function( submenu ) {
		that.toggleSubmenu( submenu, 'close' );
	}

	this.toggleSubmenu = function( submenu, action ) {
		let finalAction = action = action || 'toggle';
		let submenuListCandidates = submenu.children;
		let submenuList;

		// // alert('!!!!');
		console.log( submenuListCandidates );

		// Maintenant qu'on a tous les enfants directs, c'est lequel qui est le <ul> qu'on cherche?
		for( let thisCand of submenuListCandidates ) {
			if( thisCand.matches( that.args.dropdownSel ) ) {
				submenuList = thisCand;
				break;
			}
		}
		console.log(submenuList);

		if( that.args.debug ) console.log('Mobile menu : Maybe toggling submenu :');
		if( that.args.debug ) submenu;

		if( submenu && ! submenu.classList.contains( that.args.animatingClass ) ) {
			if( 'toggle' == action ) {
				if( submenu.classList.contains( that.args.openClass ) ) {
					finalAction = 'close';
				} else {
					finalAction = 'open';
				}
			}

			submenu.classList.add( that.args.animatingClass );

			if( 'open' == finalAction ) {
				if( ! submenu.classList.contains( that.args.openClass ) ) {
					/*if( that.args.singleSubmenu ) {
						that.closeAllSubmenus();
					}*/

					if( that.args.debug ) console.log('Mobile menu : opening submenu');

					submenuList.style.maxHeight = submenu.getAttribute('data-dropdown-height') + 'px';

					submenu.classList.add( that.args.openingClass );
					submenu.classList.add( that.args.openClass );

					submenuList.addEventListener( 'transitionend', function openDone( event ) {
						if( that.args.debug ) console.log('Mobile menu : opening submenu done!');

						submenu.classList.remove( that.args.openingClass );
						submenu.removeEventListener( 'transitionend', openDone );
					} );
				}
			} else {
				if( that.args.debug ) console.log('Mobile menu : closing submenu');

				submenuList.style.maxHeight = submenu.getAttribute('data-dropdown-default-height') + 'px';

				submenu.classList.add( that.args.closingClass );
				submenu.classList.remove( that.args.openClass );

				submenuList.addEventListener( 'transitionend', function closeDone( event ) {
					if( that.args.debug ) console.log('Mobile menu : closing submenu done!');

					submenu.classList.remove( that.args.closingClass );
					submenuList.removeEventListener( 'transitionend', closeDone );
				} );
			}

			submenu.addEventListener( 'transitionend', function animationDone( event ) {
				submenu.classList.remove( that.args.animatingClass );
				submenu.removeEventListener( 'transitionend', animationDone );
			} );
		}
	}

	// Si l'écran est trop grand/petit, les fonctions JS du menu sont désactivées

	this.viewportOk = function() {
		return ! (
			window.vWidth < that.args.viewportMin.width
			|| window.vHeight < that.args.viewportMin.height
			|| window.vWidth > that.args.viewportMax.width
			|| window.vHeight > that.args.viewportMax.height
		);
	};


	/*this.closeAllSubmenus = function() {
		let openSubmenus = that.menu.querySelectorAll( that.args.dropdownParentSel + '.' + that.args.openClass );

		openSubmenus.forEach( function( thisEl ) {
			that.toggleSubmenu( thisEl, 'close' );
		} )
	}*/

	this.init();

	if( ! this.menu ) {
		return [];
	}


	/*** Évènements *********************************************************************************/

	if( this.menu ) {
		this.button.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				event.preventDefault();
				that.toggle();
			}
		} );

		this.openSubmenuTarget.forEach( function( thisEl ) {
			thisEl.addEventListener( 'click', function( event ) {
				if( that.viewportOk() && 1 == event.which ) {
					let clickedLink = event.target.closest( that.args.linkSel );
					// let clickedSubmenu = event.target.closest( that.args.dropdownSel );
					let targetSubmenu = event.target.closest( that.args.dropdownParentSel );

					if( ! that.args.dropdownParentLinkActive && clickedLink ) {
						event.preventDefault();
					}

					// On ouvre le sous-menu À MOINS qu'on ait cliqué sur un lien actif
					if( ( ! that.args.dropdownParentLinkActive || ! clickedLink ) /*&& ! clickedSubmenu*/ ) {
						that.toggleSubmenu( targetSubmenu );
					}
				}
			} );
		} );

		// Auto-fermeture du menu quand on clique sur un lien vers la même page

		if( this.hashLinks.length > 0 ) {
			this.hashLinks.forEach( function( thisLink ) {
				thisLink.addEventListener( 'click', function( event ) {
					that.close();
				} );
			} )
		}
	}
}

export default MobileMenu;