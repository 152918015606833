// Appeler pageMenu.update() manuellement dans le scrollInterval.
// Requiert functions.changeHashWithoutScrolling
import * as functions from 'functions.js';

function PageMenu( args ) {
	let that = this;

	args = args || {};
	args.menuSel = args.menuSel || '.page-menu';
	args.menuItemSel = args.menuItemSel || '.menu-item';
	args.menuLinkSel = args.menuLinkSel || '.menu-link';
	args.activeClass = args.activeClass || 'active';
	args.scrollParent = args.scrollParent || null;
	args.analyticsMinDelay = args.analyticsMinDelay || 2000;
	args.logAnalytics = args.logAnalytics || true;
	args.autoHash = args.autoHash || true;
	// Si on n'est présentement au-dessus d'aucun élément de menu, garde le dernier élément actif si true
	args.keepLatest = args.keepLatest || false;

	that.autoHash = args.autoHash;

	this.args = args;
	this.activeItem = null;
	this.menu = null;
	this.menuItems = null;
	this.lastAnalyticsLogged = null;
	this.analyticsTimeout;

	this.init = function() {
		that.menu = document.querySelector( that.args.menuSel );
		that.activeItem = null;

		if( that.menu ) {
			that.menuItems = Array.from( that.menu.querySelectorAll( that.args.menuItemSel ) );

			// On va checher les éléments de chaque item
			that.menuItems.forEach( function( thisItem, index ) {
				let itemLink;
				let itemTarget;

				thisItem.status = null;

				for( let thisChild of thisItem.children ) {
					if( thisChild.matches( that.args.menuLinkSel ) ) {
						itemLink = thisChild;
						break;
					}
				}

				that.menuItems[index].itemLink = itemLink;

				if( itemLink.hash ) {
					itemTarget = document.querySelector( itemLink.hash ) ;
				}

				that.menuItems[index].itemTarget = itemTarget;

				if( itemTarget ) {
					if( that.scrollParent ) {
						that.menuItems[index].itemTargetOffset = itemTarget.offsetTop;
					} else {
						that.menuItems[index].itemTargetOffset = itemTarget.getBoundingClientRect().top;
					}
				} else {
					// Il n'y a rien dans la page qui ait le bon ID. On fait comme si cet item de menu n'existait pas.
					delete that.menuItems[index];
				}
			} );

			// On réindexe l'array à cause qu'on vient de supprimer des éléments de menu.
			that.menuItems = that.menuItems.filter( function( thisItem ) {
				return thisItem;
			} );

			that.update();
		}
	};

	this.reset = function() {
		that.menuItems.forEach( function( thisItem, index ) {
			that.activeItem = null;
			that.menuItems[index].status = null;
			that.menuItems[index].classList.remove( that.args.activeClass );
			that.menuItems[index].itemTarget.classList.remove( that.args.activeClass );
		} );
	};

	this.setItemStatuses = function() {
		let vCenter;
		let elementAtCenter;
		let reversedItems = that.menuItems.slice().reverse();

		if( that.args.scrollParent ) {
			let parentRect = that.args.scrollParent.getBoundingClientRect();

			vCenter = {
				x: parentRect.left + parentRect.width / 2,
				y: parentRect.top + parentRect.height / 2,
			};
		} else {
			vCenter = {
				x: window.fixedElHeight + window.vWidthExceptFixed / 2,
				y: window.fixedElHeight + window.vHeightExceptFixed / 2,
			};
		}

		// Quel élément est actuellement au centre de l'écran ?
		elementAtCenter = document.elementFromPoint( vCenter.x, vCenter.y );

		// L'élément au centre de l'écran est-il l'enfant d'un des éléments du menu ?
		if( elementAtCenter ) {
			for( let index = 0; index < that.menuItems.length; ++ index ) {
				if( that.menuItems[index].itemTarget.contains( elementAtCenter ) ) {
					if( that.args.keepLatest ) {
						that.reset();
					}

					that.menuItems[index].status = 'active';
					that.activeItem = that.menuItems[index];

					that.menuItems[index].classList.add('active');
					that.menuItems[index].itemTarget.classList.add('active');

					if( that.autoHash && that.menuItems[index].itemLink.hash ) {
						functions.changeHashWithoutScrolling( that.menuItems[index].itemLink.hash );
					}

					if( that.args.logAnalytics ) {
						that.logAnalytics( that.menuItems[index] );
					}

					break;
				}
			}
		}

		if( that.autoHash && ! that.activeItem && ! that.args.keepLatest ) {
			functions.changeHashWithoutScrolling( '' );
		}

	};

	this.logAnalytics = function( itemToLog ) {
		// Suivi Google Analytics si on reste au moins 2 secondes dans la même section
		if( that.args.logAnalytics ) {
			if( window.ga ) {
				if( null !== that.analyticsTimeout ) {
					clearTimeout( that.analyticsTimeout );
				}

				that.analyticsTimeout = setTimeout( function() {
					let theHash = itemToLog.itemLink.hash;

					if( that.lastAnalyticsLogged != theHash ) {
						window.ga( 'send', 'pageview', theHash );
						
						that.lastAnalyticsLogged = theHash;
					} else {
						that.analyticsTimeout = null;
					}
				}, that.args.analyticsMinDelay );
			}
		}
	}

	this.update = function() {
		if( ! that.args.keepLatest ) {
			that.reset();
		}
		that.setItemStatuses();
	};

	// Évènements qui désactivent l'auto-hash durant un smooth scroll
	window.addEventListener( 'smoothscrollstart', function() {
		that.autoHash = false;
	} );

	window.addEventListener( 'smoothscrollend', function() {
		that.autoHash = that.args.autoHash;
	} );

	// Initialisation
	this.init();

	if( ! this.menu ) {
		return {};
	}
}

export default PageMenu;